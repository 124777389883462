<template>
  <div class="create-class-ticket">
    <div class="card-container wrapper">
      <div
        class="font-bold text-lg text-primary-100"
        style="margin-bottom: 16px"
      >
        {{ showEditPunchCard ? '編輯次卡' : '新增次卡' }}
      </div>
      <div v-if="useEntryControl">
        <GrayBlockContainer v-if="entryControlSystemEntitlement.enable" class="w-[560px] mb-[20px]">
          <div class="flex justify-between">
            <div class="flex items-center">
              <p class="text-sub font-medium text-gray-100">次卡已綁定進場門市</p>
              <TipInfo :width="200" :size="16">
                <p>如需綁定進場門市，請至「基本參數設定 > 進場模組設定 > 編輯進場時段與收費模式」</p>
              </TipInfo>
            </div>
            <BaseElButton type="text" class="view-btn" @click="showEntryControl">查看詳情</BaseElButton>
          </div>
        </GrayBlockContainer>
        <GrayBlockContainer v-else class="w-[560px] mb-[20px]">
          <p class="text-sub text-gray-80">如需用於門市進場，請至「基本參數設定 > 進場模組設定 > 編輯進場時段與收費模式」將此次卡綁定至對應的門市、進場時段。</p>
        </GrayBlockContainer>
      </div>
      <BaseElForm
        ref="formRef"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem label="名稱" prop="name">
          <BaseElInput
            v-model="formData.name"
            testName="formData_name"
            data-cy="name-input"
            placeholder="次卡名稱"
          />
        </BaseElFormItem>
        <BaseElFormItem
          label="圖片"
          prop="image"
          class="form-relative-label others"
        >
          <UploadButton
            cyUploadBtn="ticket-img-upload-btn"
            cyUploadedImg="ticket-img-uploaded"
            :img="formData.avatar"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
        <BaseElFormItem v-if="!punchCardId || copy" label="有效期限" prop="expType">
          <div class="grid gap-[8px] items-center grid-cols-2 w-[560px]">
            <BaseElSelect
              v-model="formData.expType"
              testName="formData_expType"
              name="isExp"
              class="w-full"
              :disabled="formData.maxDayUnlimited"
              @change="onExpTypeChange"
            >
              <BaseElSelectOption label="有效天數" value="expDay" />
              <BaseElSelectOption label="特定截止日期" value="specify" />
              <BaseElSelectOption label="領取當月最後一天" value="endOfPeriod" />
            </BaseElSelect>

            <BaseElFormItem v-if="formData.expType === 'expDay'" prop="expDay">
              <BaseElInputNumber
                v-model="formData.expDay"
                testName="formData_expDay"
                :min="1"
              />
            </BaseElFormItem>
            <BaseElFormItem v-if="formData.expType === 'specify'" prop="expDate">
              <el-date-picker
                v-model="formData.expDate"
                testName="formData_expDate"
                editable
                type="date"
                format="yyyy-MM-dd"
                placeholder="選擇日期"
                :picker-options="pickerOptions"
              />
            </BaseElFormItem>
          </div>
          <BaseElCheckbox
            v-model="formData.maxDayUnlimited"
            @change="onMaxDayUnlimitedChange"
          >
            無期限
          </BaseElCheckbox>
        </BaseElFormItem>
        <BaseElFormItem v-if="showEditPunchCard" label="有效天數">
          <p class="form-edit-disable">
            {{ expireSettingContent }}
          </p>
        </BaseElFormItem>

        <BaseElFormItem v-if="showEditPunchCard && formData.expDate" label="特定截止日期">
          <p class="form-edit-disable">
            {{ `${formatDate(formData.expDate, 'YYYY/MM/DD HH:mm:ss')}` }}
          </p>
        </BaseElFormItem>
        <BaseElFormItem label="可使用次數" prop="totalAmount">
          <div class="flex flex-col w-[560px]">
            <BaseElInputNumber
              v-if="showCreateOption"
              v-model="formData.totalAmount"
              testName="formData_totalAmount"
              :min="1"
              :disabled="formData.maxAmountUnlimited"
            />
            <BaseElCheckbox
              v-if="showCreateOption"
              v-model="formData.maxAmountUnlimited"
              testName="formData_maxAmountUnlimited"
              @change="onMaxAmountUnlimitedChange"
            >
              無上限
            </BaseElCheckbox>
            <p v-if="showEditPunchCard" class="form-edit-disable">
              {{ formData.totalAmount ? `${formData.totalAmount} 次` : '無上限' }}
            </p>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="敘述" prop="description" class="w-[560px]">
          <quillEditor
            ref="quill"
            v-model="formData.description"
            testName="formData_description"
            :options="editorOption"
          />
        </BaseElFormItem>
        <BaseElFormItem label="注意事項" prop="note" class="w-[560px]">
          <quillEditor
            ref="note"
            v-model="formData.note"
            testName="formData_note"
            :options="editorOption"
          />
        </BaseElFormItem>
        <BaseElFormItem label="排序" prop="order">
          <BaseElInput
            v-model="formData.order"
            testName="formData_order"
            data-cy="order-input"
            placeholder="請輸入排序"
          />
        </BaseElFormItem>
      </BaseElForm>
    </div>
    <EntryControlConfigDialog
      v-if="dialog.entryControl"
      width="600px"
      :data="entryControlSystemEntitlement"
      :title="formData.name"
      @close="dialog.entryControl = false"
    />
    <ImageCropper
      v-if="uploadDialog"
      :image="formData.img"
      @uploaded="getImage"
      @close="uploadDialog = false"
    />
    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.push({ name: 'PunchCardSetting' })"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, set, reactive } from 'vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { noEmptyRules, noPastDate, maxNumberRules, isDigitRules } from '@/validation'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { usePermissions } from '@/use/permissions'
import {
  CreatePunchCard,
  FindPunchCard,
  UpdatePunchCard,
} from '@/api/punchCard'
import { FindEntryControlSystemEntitlement } from '@/api/entryControl'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import formUtils from '@/utils/form'
import { useBaseForm, mappingSyncFormData, onFormRulesChangeClearValidate } from '@/use/useForm'
import { useFetch } from '@/use/fetch'
import { formatDate } from '@/utils/date'
import { map, keys, has, get } from 'lodash'
import dayjs from '@/lib/dayjs'
import TipInfo from '@/components/TipInfo.vue'
import EntryControlConfigDialog from '@/components/Dialog/EntryControlConfigDialog.vue'
import notifyMessage from '@/config/notifyMessage'
export default defineComponent({
  name: 'createPunchCard',
  components: { UploadButton, quillEditor, ImageCropper, GrayBlockContainer, TipInfo, EntryControlConfigDialog },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const {
      initFormData, formData, formRef, loading,
    } = useBaseForm()
    const { simpleFetch } = useFetch()
    const shopId = computed(() => store.getters.shop)
    const punchCardId = computed(() => route.params.punchCardId)
    const copy = computed(() => route.query.copy)
    const showCreateOption = computed(() => !punchCardId.value || copy.value)
    const showEditPunchCard = computed(() => punchCardId.value && !copy.value)
    const avatarChanged = ref(false)
    const uploadDialog = ref(false)
    const { checkAction } = usePermissions()
    const dialog = reactive({
      entryControl: false,
    })
    const entryControlSystemEntitlement = ref({})
    const useEntryControl = computed(() => checkAction('admin.entryControlConfig.page'))

    initFormData({
      name: null,
      description: null,
      note: null,
      image: null,
      order: 100,
      totalAmount: null,
      expType: null,
      expDay: null,
      expDate: null,
      expireSetting: null,
      maxAmountUnlimited: false,
      maxDayUnlimited: false,
    })

    const pickerOptions = {
      disabledDate: (date) => {
        return dayjs(date).isSameOrBefore(dayjs(), 'd')
      },
    }

    const editorOption = {
      placeholder: '請輸入...',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    }

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        order: [noEmptyRules()],
        description: [noEmptyRules()],
        totalAmount: [noEmptyRules(), maxNumberRules(999)],
        expType: [noEmptyRules()],
      }

      if (formData.maxDayUnlimited) rules.expType = []
      if (formData.expType === 'expDay') rules.expDay = [noEmptyRules(), isDigitRules(), maxNumberRules(9999)]
      if (formData.expType === 'specify') rules.expDate = [noEmptyRules(), noPastDate()]
      if (showEditPunchCard.value) {
        set(rules, 'totalAmount', [])
        set(rules, 'expType', [])
      }
      return rules
    })

    onFormRulesChangeClearValidate(formRef, formRules)
    const expireSettingContent = computed(() => {
      const type = get(formData, 'expireSetting.type')
      switch (type) {
      case 'forever':
        return '無期限'
      case 'afterReceive':
        return `領取後${get(formData, 'expireSetting.afterReceiveDays')}天`
      case 'fixedDate':
        return formatDate(get(formData, 'expireSetting.fixedDate'), 'YYYY/MM/DD')
      case 'endOfPeriod':
        return '領取當月最後一天'
      default:
        return ''
      }
    })
    const loadImg = (img) => {
      formData.img = img
      uploadDialog.value = true
    }

    const getImage = (data) => {
      formData.avatar = data
      avatarChanged.value = true
      uploadDialog.value = false
    }

    const formatExpSetting = () => {
      const expType = formData.expType
      const maxDayUnlimited = formData.maxDayUnlimited
      const expireSetting = {}

      if (maxDayUnlimited) {
        expireSetting.type = 'forever'
      } else if (expType === 'expDay') {
        expireSetting.afterReceiveDays = formData.expDay || 1
        expireSetting.type = 'afterReceive'
      } else if (expType === 'specify') {
        expireSetting.fixedDate = formData.expDate || undefined
        expireSetting.type = 'fixedDate'
      } else if (expType === 'endOfPeriod') {
        expireSetting.type = 'endOfPeriod'
        expireSetting.endOfPeriodUnit = 'month'
      }

      return expireSetting
    }

    //  新增堂票
    const createPunchCard = async () => {
      const {
        name,
        description,
        note,
        order,
        totalAmount,
        avatar,
        expType,
        expDay,
        expDate,
        maxDayUnlimited,
        maxAmountUnlimited,
      } = formData
      const [, err] = await CreatePunchCard({
        shopId: shopId.value,
        name,
        description,
        note: note || undefined,
        order,
        totalAmount: maxAmountUnlimited ? null : totalAmount,
        expireSetting: formatExpSetting(),
        image: avatar?.id,
        imageId: avatar?.id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.createSuccess)
      router.push({ name: 'PunchCardSetting' })
    }

    //  更新堂票，id經由syncData寫入
    const updatePunchCard = async () => {
      const {
        name,
        description,
        note,
        order,
        totalAmount,
        avatar,
        expType,
        expDay,
        expDate,
        maxDayUnlimited,
        id,
      } = formData

      const [, err] = await UpdatePunchCard({
        shopId: shopId.value,
        id,
        name,
        description,
        note: note || undefined,
        totalAmount,
        expireSetting: formatExpSetting(),
        image: avatar?.id,
        order,
        // appointmentServices: map(appointmentServices, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      router.push({ name: 'PunchCardSetting' })
    }

    const syncData = (res) => {
      mappingSyncFormData({ Image: 'avatar', specifyExpiredDate: 'expDate', AppointmentServices: 'appointmentServices', expDate: 'specifyExpiredDate' }, res, formData)
      const type = get(formData, 'expireSetting.type')
      if (type === 'forever') formData.maxDayUnlimited = true
      else if (type === 'afterReceive') {
        formData.expType = 'expDay'
        formData.expDay = get(formData, 'expireSetting.afterReceiveDays')
      } else if (type === 'fixedDate') {
        formData.expType = 'specify'
        formData.expDate = get(formData, 'expireSetting.fixedDate')
      } else if (type === 'endOfPeriod') {
        formData.expType = 'endOfPeriod'
      }
      if (formData.totalAmount === null) formData.maxAmountUnlimited = true
    }

    const handleConfirm = async () => {
      loading.value = true
      const pass = await formUtils.checkForm(formRef.value)
      if (!pass) {
        loading.value = false
        return
      }
      if (showEditPunchCard.value) await updatePunchCard()
      else await createPunchCard()
      loading.value = false
    }

    const onExpTypeChange = () => {
      formData.expDay = null
      formData.expDate = null
    }
    const onMaxAmountUnlimitedChange = (toggle) => {
      if (toggle) formData.totalAmount = null
    }
    const onMaxDayUnlimitedChange = (toggle) => {
      if (toggle) formData.expType = null
    }
    const showEntryControl = async () => {
      dialog.entryControl = true
    }
    const findEntryControlSystemEntitlement = async () => {
      const [res, err] = await FindEntryControlSystemEntitlement({
        shopId: shopId.value,
        entitlementType: 'punchCard',
        entitlementId: punchCardId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      entryControlSystemEntitlement.value = res
    }
    onMounted(() => {
      if (punchCardId.value) {
        simpleFetch(FindPunchCard, {
          shopId: shopId.value,
          id: punchCardId.value,
        },
        (res) => {
          syncData(res)
        })
        findEntryControlSystemEntitlement()
      }
    })

    return {
      pickerOptions,
      punchCardId,
      formData,
      editorOption,
      loadImg,
      uploadDialog,
      getImage,
      formRules,
      handleConfirm,
      loading,
      formRef,
      formatDate,
      onExpTypeChange,
      copy,
      showEditPunchCard,
      showCreateOption,
      useEntryControl,
      onMaxAmountUnlimitedChange,
      onMaxDayUnlimitedChange,
      expireSettingContent,
      showEntryControl,
      dialog,
      entryControlSystemEntitlement,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-select .el-input {
  @apply w-full;
}

::v-deep .el-input-number,
::v-deep .el-input-number .el-input,
::v-deep .el-date-editor {
  @apply w-full;
}

.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}

.form-relative-label {
  @apply relative;
}

.form-relative-label.others::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[50px] top-[2px] text-sm text-gray-80;
}

.el-form-item__label {
  @apply p-0;
}

.ql-container {
  @apply h-[200px];
}

.form-edit-disable {
  color: #606266;
}
.view-btn {
  @apply border-solid border-[1px] border-primary-100 py-[3.5px] px-[12px] text-xs font-medium;
}
</style>
